import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { indexable } from './indexableRoutes';
import NoRobotsAllowed from './NoRobotsAllowed';

const RobotsRoutes = () => (
  <Switch>
    {indexable.map((path, index) => (
      <Route exact path={path} component={null} key={index} />
    ))}
    <Route component={NoRobotsAllowed} />
  </Switch>
);

export default RobotsRoutes;
